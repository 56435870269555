const ValidationRules: any = {
    min: (v) => v?.length >= 8 || 'Min 8 characters',
    password: (v) => v?.length >= 8 || 'Your password must be at least 8 characters long. It can be any combination of letters, numbers and symbols',
    accessCode: (v) => (v?.length >= 4 && v?.length <= 15) || 'The password should be minimum - 4 characters or maximum - 15 characters',
    spaceChar: (v) => !v?.includes(' ') || 'Space in string',
    channelDescription: (v) => v?.length <= 100 || 'Description must be less than 100 characters',
    required: (v) => !!v || 'Field is required',
    doubleDot: (v: string | null) => !v?.includes('..') || 'Double dots',
    validEmail: (v) => /^[a-zA-Z0-9]+[a-zA-Z0-9+._-]*@[a-zA-Z0-9]+[a-zA-Z0-9._-]*\.[a-zA-Z0-9]{2,6}$/.test(v) || 'E-mail must be valid',
    name: [
        (v) => (!!v && v.trim().length >0)  || 'Field is required',
        (v) => (v && v.length && v.length <= 30) || 'Name must be less than 30 characters'
    ],
    channelName: [
        (v) => (!!v && v.trim().length >0)  || 'Field is required',
        (v) => (v && v.length && v.length <= 25) || 'Name must be less than 25 characters'
    ],
    position: [
        (v) => !!v || 'Field is required',
        (v) => (v && v.length <= 30) || 'Position must be less than 30 characters'
    ],
    email: [
        (v) => !!v || 'Field is required',
        (v: string | null) => !v?.includes('..') || 'E-mail must be valid (double dots)',
        (v) => /^[a-zA-Z0-9]+[a-zA-Z0-9+._-]*@[a-zA-Z0-9]+[a-zA-Z0-9._-]*\.[a-zA-Z0-9]{2,6}$/.test(v) || 'E-mail must be valid'
    ],
    softName: (v) => !v || (v && v.length && v.length <= 30) || 'Name must be less than 30 characters',
    softEmail: (v) => !v || /^[a-zA-Z0-9]+[a-zA-Z0-9+._-]*@[a-zA-Z0-9]+[a-zA-Z0-9._-]*\.[a-zA-Z0-9]{2,6}$/.test(v) || 'E-mail must be valid',
    phone: (v) => !v || /^1-\d{3}-\d{3}-\d{4}$/.test(v) || 'Phone number must be valid',
    web: (v) => !v || /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\.)+[\w]{2,}(\/\S*)?$/.test(v) || 'Website is invalid',
    depPath: (v) => !v.endsWith('/') || 'Invalid department name',
    depName: (v) => !v.includes('/') || 'Invalid department name. "/" is not allowed.',
    passwordMatch: () => ('The passwords you entered don\'t match'),
    lowercase: (v) => /^[^A-Z]+$/.test(v) || 'Field must contain only lowercase letters',
    zipCode: (v) => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(v) || 'Invalid ZIP Code'
};

ValidationRules.install = (Vue, options) => {
    Vue.prototype.$validations = ValidationRules
}

export default ValidationRules
