import {BusinessContact, ContactsState} from '@/domain/model/types';
import {firestore, storage} from '@/plugins/firebase.init';
import constants from '@/common/constants';
import {ActionTree, GetterTree, MutationTree} from 'vuex';
import axios from '@/plugins/axios';
import {
  arrayRemove,
  CollectionReference,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
  writeBatch,
  WriteBatch
} from 'firebase/firestore';
import {appConfig, dynamicLinkConfig} from '@/plugins/firebase.config';
import rfdc from 'rfdc';
import {flattenDirectory} from '@/utils/helpers';
import {
  businessCustomer,
  businessCustomers,
  businessDirectory,
  businessDirectoryContact,
  businessDirectoryContactBacking,
  businessDirectoryContactBackingContact,
  user,
  users
} from '@/data/firebase';
import {ref, uploadBytes} from 'firebase/storage';
import deleteProperty = Reflect.deleteProperty;

const clone = rfdc({proto: true})

const defaultState: ContactsState = {
  selectedContact: null,
  businessDirectory: [],
  sourceBusinessDirectory: [],
  directoryOriginals: [],
  contacts: [],
  directoryNodes: {},
  // create contact related
  // contactIsGroup: false,
  associatesOriginal: [],
  selectedAssociate: null,
  showCreateEditDepartment: false,
  directoryInEditMode: false,
  selectedDepartment: null,
  snackbar: {show: false, timeout: 5000, text: null},
  workingStates: [{name: 'Active', value: true}, {name: 'Inactive', value: false}, {
    name: 'Absent',
    value: false
  }, {name: 'Break', value: false}],
  associateContacts: [],
  associateGroupContacts: [],
  contactsOwners: [],
  contactsAdmins: [],
  backupContacts: [],
  selectedCustomer: null,
  customerContacts: [],
  customersCount: 0,
  vipCustomersCount: 0,
  optinCustomersCount: 0,
  personalCount: 0,
  sharedCount: 0,
  pendingDeleteContact: [],
  pendingIndexUpdateContacts: [],
  pendingPathUpdateContacts: [],
  pendingUpdateDepartments: []
};

const state: ContactsState = Object.assign({}, defaultState);

const getters: GetterTree<ContactsState, any> = {
  selectedContact: (state) => state.selectedContact,
  businessDirectory: (state) => state.businessDirectory,
  directoryOriginals: (state) => state.directoryOriginals,
  contacts: (state) => state.contacts,
  directoryNodes: (state) => state.directoryNodes,
  associatesOriginal: (state) => state.associatesOriginal,
  selectedAssociate: (state) => state.selectedAssociate,
  showCreateEditDepartment: (state) => state.showCreateEditDepartment,
  directoryInEditMode: (state) => state.directoryInEditMode,
  selectedDepartment: (state) => state.selectedDepartment,
  snackbar: (state) => state.snackbar,
  workingStates: (state) => state.workingStates,
  associateContacts: (state) => state.associateContacts,
  associateGroupContacts: (state) => state.associateGroupContacts,
  contactsOwners: (state) => state.contactsOwners,
  contactsAdmins: (state) => state.contactsAdmins,
  backupContacts: (state) => state.backupContacts,
  selectedCustomer: (state) => state.selectedCustomer,
  customers: (state) => state.customerContacts,
  personalCustomers: (state, rootGetters) => {
    const id = rootGetters.t2bUser.id
    return state.customerContacts
      .filter((customer) => !!customer.personal?.includes(id))
      .filter((customer) => customer.permissions.contact)
  },
  sharedCustomers: (state) => state.customerContacts?.filter((customer) => customer.shared) || [],
  optedInCustomers: (state) => state.customerContacts?.filter((customer) => customer.permissions.contact) || [],
  blockedCustomers: (state, rootGetters) => {
    const id = rootGetters.t2bUser.id
    return state.customerContacts.filter((customer) => !!customer.blocked?.includes(id))
  },
  customersCount: (state) => state.customersCount,
  vipCustomersCount: (state) => state.vipCustomersCount,
  optinCustomersCount: (state) => state.optinCustomersCount,
  personalCount: (state) => state.personalCount,
  blockedCount: (state, getters) => getters.blockedCustomers.length,
  sharedCount: (state) => state.sharedCount
};

const mutations: MutationTree<ContactsState> = {
  addPendingDeleteContact(state, item) {
    state.pendingDeleteContact.push(item)
  },
  addPendingDeleteContacts(state, items) {
    state.pendingDeleteContact.push(...items)
  },
  clearPendingDeleteContact(state) {
    state.pendingDeleteContact = []
  },
  setPendingIndexUpdateContacts(state, items) {
    state.pendingIndexUpdateContacts = items
  },
  clearPendingIndexUpdateContacts(state) {
    state.pendingIndexUpdateContacts = []
  },
  setPendingPathUpdateContacts(state, items) {
    state.pendingPathUpdateContacts = items
  },
  clearPendingPathUpdateContacts(state) {
    state.pendingPathUpdateContacts = []
  },
  setSelectedContact(state, contact) {
    state.selectedContact = contact;
  },
  setDirectoryOriginals(state, originals) {
    state.directoryOriginals = originals;
  },
  setContacts(state, contacts) {
    state.contacts = contacts;
  },
  setAssociatesOriginal(state, value) {
    state.associatesOriginal = value;
  },
  addAssociate(state, associate) {
    state.associatesOriginal.push(associate);
  },
  updateAssociate(state, associate) {
    state.associatesOriginal.splice(state.associatesOriginal.findIndex((item) => item.id === associate.id), 1, associate);
  },
  deleteAssociate(state, associateId) {
    state.associatesOriginal.splice(state.associatesOriginal.findIndex((item) => item.id === associateId), 1);
  },
  setSelectedAssociate(state, associate) {
    state.selectedAssociate = associate;
  },
  setShowCreateEditDepartment(state, value) {
    state.showCreateEditDepartment = value;
  },
  setBusinessDirectory(state, value) {
    state.businessDirectory = value;
  },
  setSourceBusinessDirectory(state, value) {
    state.sourceBusinessDirectory = value;
  },
  addContactToDirectory(state, contact) {
    if (state.businessDirectory.indexOf(contact) === -1) {
      state.businessDirectory.push(contact);
    }
  },
  addContactToSourceDirectory(state, contact) {
    if (state.sourceBusinessDirectory.indexOf(contact) === -1) {
      state.sourceBusinessDirectory.push(contact);
    }
  },
  setDirectoryEditMode(state, value) {
    state.directoryInEditMode = value;
  },
  setSelectedDepartment(state, department) {
    state.selectedDepartment = department;
  },
  setSnackbar(state, snackbar) {
    state.snackbar = snackbar;
  },
  resetState(state) {
    state = Object.assign({}, defaultState);
  },
  setAssociateContacts(state, value) {
    state.associateContacts = value;
  },
  setAssociateGroupContacts(state, value) {
    state.associateGroupContacts = value;
  },
  setContactsOwners(state, value) {
    state.contactsOwners = value;
  },
  setContactsAdmins(state, value) {
    state.contactsAdmins = value;
  },
  addContactsOwner(state, value) {
    if (state.contactsOwners.findIndex((item) => item.id === value.id) === -1) {
      state.contactsOwners.push(value);
    }
  },
  updateContactsOwner(state, {contact, oldContact}) {
    state.contactsOwners.splice(state.contactsOwners.indexOf(oldContact), 1, contact);
  },
  removeContactsOwner(state, oldContact) {
    state.contactsOwners.splice(state.contactsOwners.indexOf(oldContact), 1);
  },
  addContactsAdmin(state, value) {
    if (state.contactsAdmins.findIndex((item) => item.id === value.id) === -1) {
      state.contactsAdmins.push(value);
    }
  },
  updateContactsAdmin(state, {contact, oldContact}) {
    state.contactsAdmins.splice(state.contactsAdmins.indexOf(oldContact), 1, contact);
  },
  removeContactsAdmin(state, oldContact) {
    state.contactsAdmins.splice(state.contactsAdmins.indexOf(oldContact), 1);
  },
  addAssociateContact(state, value) {
    if (state.associateContacts.findIndex((item) => item.id === value.id) === -1) {
      state.associateContacts.push(value);
    }
  },
  updateAssociateContact(state, {contact, oldContact}) {
    state.associateContacts.splice(state.associateContacts.indexOf(oldContact), 1, contact);
  },
  removeAssociateContact(state, oldContact) {
    state.associateContacts.splice(state.associateContacts.indexOf(oldContact), 1);
  },
  addAssociateGroupContact(state, value) {
    if (state.associateGroupContacts.findIndex((item) => item.id === value.id) === -1) {
      state.associateGroupContacts.push(value);
    }
  },
  updateAssociateGroupContact(state, {contact, oldContact}) {
    state.associateGroupContacts.splice(state.associateGroupContacts.indexOf(oldContact), 1, contact);
  },
  removeAssociateGroupContact(state, oldContact) {
    state.associateGroupContacts.splice(state.associateGroupContacts.indexOf(oldContact), 1);
  },
  clearBackupContacts(state) {
    state.backupContacts = [];
  },
  addBackupContact(state, contact) {
    state.backupContacts.push(contact);
  },
  removeBackupContact(state, contact) {
    const original = state.backupContacts.find((item) => item.id === contact.id);
    if (!original) {
      return;
    }
    const index = state.backupContacts.indexOf(original);
    state.backupContacts.splice(index, 1);
  },
  setSelectedCustomer(state, customer) {
    state.selectedCustomer = customer;
  },
  updateSelectedCustomer(state, customer) {
    state.selectedCustomer = customer;
  },
  setCustomerContacts(state, customers) {
    state.customerContacts = customers;
  },
  setCustomersCount(state, count) {
    state.customersCount = count;
  },
  setVipCustomersCount(state, count) {
    state.vipCustomersCount = count;
  },
  setOptinCustomersCount(state, count) {
    state.optinCustomersCount = count;
  },
  addCustomerContact(state, customer) {
    state.customerContacts.push(customer);
  },
  updateCustomerContact(state, customer) {
    state.customerContacts.splice(state.customerContacts.findIndex((item) => item.id === customer.id), 1, customer);
    console.log('updateCustomerContact, ID=', customer.id)
  },
  deleteCustomerContact(state, customer) {
    state.customerContacts.splice(state.customerContacts.findIndex((item) => item.id === customer.id), 1);
  },
  setPersonalCount(state, count) {
    state.personalCount = count;
  },
  setSharedCount(state, count) {
    state.sharedCount = count;
  },
  addPendingUpdateDepartment(state, department) {
    state.pendingUpdateDepartments.push(department)
  },
  clearPendingUpdateDepartments(state) {
    state.pendingUpdateDepartments = []
  }
};

const actions: ActionTree<ContactsState, any> = {
  loadPersonalContacts({rootGetters}) {
    const businessId = rootGetters.business && rootGetters.business.id;
    const userId = rootGetters.t2bUser && rootGetters.t2bUser.id;
    if (!businessId || !userId) {
      return null;
    }
    return query(businessCustomers(businessId),
      where('personal', 'array-contains', userId),
      orderBy('fullName'))
  },
  deleteGroupMember({commit}, {contact, contactId}) {
    if (!contact || !contactId) {
      return null;
    }
    const contacts: BusinessContact[] = contact.contacts;
    const member: BusinessContact | undefined = contacts.find((item) => item.id === contactId);
    if (!member) {
      return null;
    }
    contacts.splice(contacts.indexOf(member), 1);
    const contactRef = businessDirectoryContact(contact.business.id, contact.id);
    return updateDoc(contactRef,
      'contacts', contacts, 'associateIDs', arrayRemove(member.associate!.id));
  },
  deleteBackingContact({commit}, {contact, backingId}) {
    if (!contact || !backingId) {
      return null;
    }
    const backingContact = businessDirectoryContactBackingContact(contact.business.id, contact.id, backingId);
    return deleteDoc(backingContact);
  },
  addContactsToBackupList({commit}, {contact, contacts}) {
    if (!contact || !contacts) {
      return null;
    }
    const batch = writeBatch(firestore);
    for (const item of contacts) {
      const backingContact = businessDirectoryContactBackingContact(contact.business.id, contact.id, item.id);
      batch.set(backingContact, {
        uid: item.associate.id,
        business: item.business,
        name: item.name,
        email: item.email,
        photoUrl: item.photoUrl || null,
        position: item.position,
        workingStatus: item.associate?.workingStatus || null,
        status: item.associate?.status || null,
        type: item.type,
        created: Timestamp.now()
      });
    }
    return batch.commit();
  },
  loadBackingContacts({commit}, contact) {
    if (!contact) {
      return null;
    }
    commit('clearBackupContacts');
    const backing = businessDirectoryContactBacking(contact.business.id, contact.id);
    return onSnapshot(backing, (snapshot) => {
      snapshot.docChanges().forEach((docChange) => {
        const doc = docChange.doc;
        const backupContact = doc.data();
        backupContact.id = doc.id;
        switch (docChange.type) {
          case 'added':
            commit('addBackupContact', backupContact);
            break;
          case 'modified':
            // commit('updateBackupContact', backupContact);
            break;
          case 'removed':
            commit('removeBackupContact', backupContact);
            break;
          default:
        }
      });
    });
  },
  uploadProfilePhoto({commit}, {associateId, imageFile}) {
    if (!!associateId || !!imageFile) {
      return null;
    }
    const imagesRef = ref(storage, `users/${associateId}/profilePhoto/${imageFile.name}`);
    return uploadBytes(imagesRef, imageFile);
  },
  savePrivateInfoChanges({commit, rootGetters}, associate) {
    const userId = rootGetters.t2bUser && rootGetters.t2bUser.id;
    if (!associate || !userId) {
      return null;
    }
    const data = Object.assign({}, associate);
    deleteProperty(data, 'id');
    return setDoc(user(userId), data, {merge: true});
  },
  saveContactInfoChanges({commit, rootGetters}, contact) {
    const userId = rootGetters.t2bUser && rootGetters.t2bUser.id;
    const businessId = rootGetters.business && rootGetters.business.id;
    if (!contact || !userId || !businessId) {
      return null;
    }
    contact.phoneNumber = contact.phoneNumber?.replace(/[^0-9]/g, '') || ''
    const data = Object.assign({}, contact);
    deleteProperty(data, 'id');
    return setDoc(businessDirectoryContact(businessId, contact.id), data, {merge: true});
  },
  async changeAdminRole({commit}, {id, admin}) {
    await updateDoc(user(id), 'admin', admin, 'roles.admin', admin);
  },
  async shareContactLink({rootGetters}, contactId) {
    const businessId = rootGetters.business?.id;
    if (!contactId || !businessId) {
      return null;
    }
    const response = await axios.post('/shortLinks', {
      dynamicLinkInfo: {
        domainUriPrefix: `https://${dynamicLinkConfig.actionCodeSettings.dynamicLinkDomain}/share/contact`,
        link: `${dynamicLinkConfig.actionCodeSettings.url}/businesses/${businessId}/contacts/directory/${contactId}`,
        androidInfo: {
          androidPackageName: dynamicLinkConfig.actionCodeSettings.android!!.packageName
        },
        iosInfo: {
          iosBundleId: dynamicLinkConfig.actionCodeSettings.iOS!!.bundleId
        }
      },
      suffix: {
        option: 'SHORT'
      }
    }, {
      baseURL: 'https://firebasedynamiclinks.googleapis.com/v1',
      params: {key: appConfig.apiKey},
      headers: {
        ['Content-Type']: 'application/json'
      },
      validateStatus: (status) => status === 200
    });
    return response.data.shortLink;
  },
  async shareCustomerContactLink({rootGetters}, customerId) {
    const businessId = rootGetters.business?.id;
    if (!customerId || !businessId) {
      return null;
    }
    const response = await axios.post('/shortLinks', {
        dynamicLinkInfo: {
          domainUriPrefix: `https://${dynamicLinkConfig.actionCodeSettings.dynamicLinkDomain}/share/contact`,
          link: `${dynamicLinkConfig.actionCodeSettings.url}/businesses/${businessId}/contacts/customers/${customerId}`,
          androidInfo: {
            androidPackageName: dynamicLinkConfig.actionCodeSettings.android!!.packageName
          },
          iosInfo: {
            iosBundleId: dynamicLinkConfig.actionCodeSettings.iOS!!.bundleId
          }
        },
        suffix: {
          option: 'SHORT'
        }
      },
      {
        baseURL: 'https://firebasedynamiclinks.googleapis.com/v1',
        params: {key: appConfig.apiKey},
        headers: {
          ['Content-Type']: 'application/json'
        },
        validateStatus: (status) => status === 200
      });
    return response.data.shortLink
  },
  selectContact({commit}, contact) {
    commit('setSelectedContact', contact);
  },
  selectCustomer({commit}, contact) {
    commit('setSelectedCustomer', contact);
  },
  fetchBusinessDirectory2({state, commit, rootGetters, dispatch}) {
    const businessId = rootGetters.t2bUser ? rootGetters.t2bUser.business.id : null;
    if (!businessId) {
      return null;
    }
    const contactsQuery = query(businessDirectory(businessId), orderBy('flatIndex'));
    return onSnapshot(contactsQuery, (snapshot) => {
        const contacts: BusinessContact[] = snapshot.docs.map((doc) => {
          return {...doc.data(), id: doc.id} as BusinessContact
        });
        commit('setContacts', contacts);
        commit('setDirectoryOriginals', contacts);
        commit('setBusinessDirectory', [])
        commit('setSourceBusinessDirectory', [])
        contacts.forEach((contact) => {
          if (!contact.path || !contact.path.length) {
            commit('addContactToSourceDirectory', contact);
          } else {
            let rootNode: BusinessContact | null | undefined;
            const pathArray: string[] = contact.path;
            pathArray.forEach((node, index) => {
              // @ts-ignore
              if (index === 0) {
                rootNode = state.sourceBusinessDirectory.find((item) => item.name === node);
              } else {
                rootNode = rootNode && rootNode.contacts && rootNode.contacts.find((item) => item.name === node);
              }
            });

            if (rootNode && !rootNode.contacts) {
              rootNode.contacts = [];
            }
            if (rootNode) {
              rootNode.contacts!.push(contact);
            }
          }
        })
        commit('setBusinessDirectory', clone(state.sourceBusinessDirectory))
      },
      (error) => console.log(error));
  },
  async fixDirectoryIndexes({}, {directoryRef, contacts}) {
    const itemsToUpdate = contacts.filter((item, index) => {
      const incorrectIndex = index !== item.flatIndex
      if (incorrectIndex) {
        item.flatIndex = index
      }
      return incorrectIndex
    })
    console.log('fixDirectoryIndexes => items count:', itemsToUpdate.length)
    const batch = writeBatch(firestore)
    for (const item of itemsToUpdate) {
      batch.update(doc(directoryRef, item.id), 'flatIndex', item.flatIndex)
    }
    try {
      await batch.commit()
    } catch (err) {
      console.error('fixDirectoryIndexes:', err)
    }
  },
  fetchAssociates({commit, getters, rootGetters}) {
    const businessId = rootGetters.business.id;
    if (!businessId) {
      return null;
    }
    commit('setAssociatesOriginal', []);
    const usersQuery = query(users,
      where('business.id', '==', businessId),
      where('type', '==', constants.TYPE_ASSOCIATE),
      orderBy('fullName'))
    return onSnapshot(usersQuery, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const userData = change.doc.data();
          userData.id = change.doc.id;
          switch (change.type) {
            case 'added': {
              commit('addAssociate', userData);
              break;
            }
            case 'modified': {
              commit('updateAssociate', userData);
              break;
            }
            case 'removed': {
              commit('deleteAssociate', userData.id);
              break;
            }
            default:
              console.log('Change unrecognized');
          }
        });
        getters.associatesOriginal.sort((item1, item2) => {
          const admin1 = item1.admin;
          const admin2 = item2.admin;
          return admin1 === admin2 ? 0 : (admin1 ? -1 : 1);
        });
        getters.associatesOriginal.sort((item1, item2) => {
          const owner1 = item1.owner;
          const owner2 = item2.owner;
          return owner1 === owner2 ? 0 : (owner1 ? -1 : 1);
        });
        getters.associatesOriginal[0].header = 'Business Owners';
        getters.associatesOriginal.find((item) => item.admin).header = 'Business Admins';
        getters.associatesOriginal.find((item) => !item.admin).header = 'Associates';
      },
      (error) => console.log(error),
      () => console.log('Completed')
    );
  },
  loadCustomers({commit, getters, rootGetters}) {
    const userId = rootGetters.t2bUser.id;
    const businessId = rootGetters.business.id;
    if (!businessId || !userId) {
      return null;
    }
    commit('setCustomerContacts', []);
    const customersQuery = query(businessCustomers(businessId), orderBy('fullName'))
    return onSnapshot(customersQuery, (snapshot) => {
      commit('setCustomersCount', snapshot.size);
      commit('setVipCustomersCount', snapshot.docs.reduce((count, item) => item.data().vip ? ++count : count, 0));
      commit('setOptinCustomersCount', snapshot.docs.reduce((count, item) => {
        const permissions = item.data().permissions;
        return permissions && (permissions.contact || permissions.promote) ? ++count : count;
      }, 0));
      commit('setPersonalCount', snapshot.docs.reduce((count, item) => {
        const personal = item.data().personal;
        return personal && personal.includes(userId) ? ++count : count;
      }, 0));
      commit('setSharedCount', snapshot.docs.reduce((count, item) => item.data().shared ? ++count : count, 0));
      snapshot.docChanges().forEach((change) => {
        const contact = change.doc.data();
        contact.id = change.doc.id;
        switch (change.type) {
          case 'added': {
            commit('addCustomerContact', contact);
            break;
          }
          case 'modified': {
            commit('updateCustomerContact', contact);
            if (getters.selectedCustomer && getters.selectedCustomer.id === contact.id) {
              commit('updateSelectedCustomer', contact);
            }
            break;
          }
          case 'removed': {
            commit('deleteCustomerContact', contact);
            if (getters.selectedCustomer && getters.selectedCustomer.id === contact.id) {
              commit('setSelectedCustomer', null);
            }
            break;
          }
          default:
            console.log('Change unrecognized');
        }
      });
    }, (error) => console.log(error));
  },
  async loadCustomerById({commit, state, rootGetters}, customerId) {
    const found = state.customerContacts.find((customer) => customer.id === customerId);
    if (!!found) {
      commit('setSelectedCustomer', found);
      return
    }
    const userId = rootGetters.t2bUser.id;
    const businessId = rootGetters.business.id;
    if (!businessId || !userId) {
      return
    }
    const snapshot = await getDoc(businessCustomer(businessId, customerId))
    if (snapshot.exists()) {
      commit('setSelectedCustomer', {id: snapshot.id, ...snapshot.data()});
    }
  },
  loadContacts({commit, getters, rootGetters}) {
    const businessId = rootGetters.business.id;
    if (!businessId) {
      return null;
    }
    commit('setAssociateContacts', []);
    commit('setAssociateGroupContacts', []);
    commit('setContactsOwners', []);
    commit('setContactsAdmins', []);
    const contactsQuery = query(businessDirectory(businessId),
      where('type', '<', 3),
      orderBy('type'),
      orderBy('name'))
    return onSnapshot(contactsQuery, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const contact = change.doc.data();
          contact.id = change.doc.id;
          const predicate = (item) => item.id === contact.id;
          switch (change.type) {
            case 'added': {
              if (contact.type === 2) {
                commit('addAssociateGroupContact', contact);
              } else if (contact.associate.owner) {
                commit('addContactsOwner', contact);
              } else if (contact.associate.admin) {
                commit('addContactsAdmin', contact);
              } else {
                commit('addAssociateContact', contact);
              }
              break;
            }
            case 'modified': {
              if (getters.selectedContact && getters.selectedContact.id === contact.id) {
                commit('setSelectedContact', contact);
              }
              let oldContact = state.associateGroupContacts.find(predicate);
              if (oldContact) {
                commit('updateAssociateGroupContact', {contact, oldContact});
                break;
              }
              oldContact = state.associateContacts.find(predicate);
              if (oldContact) {
                if (contact.associate.admin) {
                  commit('removeAssociateContact', oldContact);
                  commit('addContactsAdmin', contact);
                } else {
                  commit('updateAssociateContact', {contact, oldContact});
                }
                break;
              }
              oldContact = state.contactsOwners.find(predicate);
              if (oldContact) {
                commit('updateContactsOwner', {contact, oldContact});
                break;
              }
              oldContact = state.contactsAdmins.find(predicate);
              if (oldContact) {
                if (!contact.associate.admin) {
                  commit('removeContactsAdmin', oldContact);
                  commit('addAssociateContact', contact);
                } else {
                  commit('updateContactsAdmin', {contact, oldContact});
                }
              }
              break;
            }
            case 'removed': {
              let oldContact = state.associateGroupContacts.find(predicate);
              if (oldContact) {
                commit('removeAssociateGroupContact', oldContact);
                break;
              }
              oldContact = state.associateContacts.find(predicate);
              if (oldContact) {
                commit('removeAssociateContact', oldContact);
                break;
              }
              oldContact = state.contactsOwners.find(predicate);
              if (oldContact) {
                commit('removeContactsOwner', oldContact);
                break;
              }
              oldContact = state.contactsAdmins.find(predicate);
              if (oldContact) {
                commit('removeContactsAdmin', oldContact);
              }
              break;
            }
            default:
              console.log('Change unrecognized');
          }
        });
      },
      (error) => console.log(error),
      () => console.log('Completed')
    );
  },
  onAssociateSelected({commit, getters}, associate) {
    const associateId = associate && associate.id;
    const selectedAssociateId = getters.selectedAssociate && getters.selectedAssociate.id;
    if (associateId === selectedAssociateId) {
      return;
    }
    commit('setSelectedAssociate', associate);
  },
  closeCreateEditDepartment({commit}) {
    commit('setSelectedDepartment', null);
    commit('setShowCreateEditDepartment', false);
  },
  createDepartment({commit}) {
    commit('setShowCreateEditDepartment', true);
  },
  editDepartment({commit, dispatch}, department) {
    commit('setSelectedDepartment', department);
    commit('setShowCreateEditDepartment', true);
  },
  onEditDepartment({commit, getters, dispatch}, department) {
    console.log(`onEditDepartment => editedDepartment=${department.name}`)
    const contactsDiff = department.contacts.length - (state.selectedDepartment!.contacts?.length || 0)
    if (contactsDiff > 0) { // contacts added
      const addedContacts = department.contacts
        .filter((contact) => {
          return !state.selectedDepartment!.contacts?.find((item) => item.id === contact.id)
        })
      addedContacts.forEach((contact) => {
        let root = state.businessDirectory
        if (contact.path?.length) {
          for (const pathName of contact.path) {
            root = root.find((item) => item.name === pathName)?.contacts as BusinessContact[] || root
          }
        }
        const contactIndex = root.findIndex((item) => item.id === contact.id)
        if (contactIndex !== -1) {
          console.log(`onEditDepartment => move contact contactIndex=${contactIndex}`)
          root.splice(contactIndex, 1)
        }
        // contact.path = Object.assign([], [...department.path, department.name])
      })
    }
    if (contactsDiff < 0) { // contacts removed
      const removedContacts = state.selectedDepartment!.contacts?.filter((contact) => {
        return !department.contacts.find((item) => item.id === contact.id)
      })
      removedContacts?.forEach((contact) => {
        // const contactIndex = state.selectedDepartment!.contacts!.findIndex((item) => item.id === contact.id)
        // console.log(`onEditDepartment => move contact contactIndex=${contactIndex}`)
        // state.selectedDepartment!.contacts!.splice(contactIndex, 1)
        // contact.path = []
        state.businessDirectory.push(contact)
      })
      console.log(`onEditDepartment => paths to change=${removedContacts?.length}`)
      // commit('setPendingPathUpdateContacts', removedContacts)
    }
    // const newName = department.name;
    // const oldName = state.selectedDepartment!.name;
    // const nameChanged: boolean = oldName !== newName
    // if (nameChanged) {
    //     updateChildrenPath(department.contacts, oldName, newName)
    // }
    const newVisibility: boolean = department.rules.VISIBILITY.visible
    const oldVisibility: boolean = !!state.selectedDepartment!.rules?.VISIBILITY?.visible
    const visibilityChanged: boolean = newVisibility !== oldVisibility
    if (visibilityChanged) {
      updateChildrenVisibility(department.contacts, newVisibility)
    }
    Object.assign(state.selectedDepartment, department)
    commit('addPendingUpdateDepartment', state.selectedDepartment)
  },
  deleteContact({commit, dispatch, state}, contact) {
    let root = state.businessDirectory
    if (contact.path?.length) {
      contact.path?.forEach((pathName) => {
        root = root.find((item) => item.name === pathName)?.contacts as BusinessContact[] || root
      })
    }
    const contactIndex = root.findIndex((item) => item.id === contact.id)
    commit('addPendingDeleteContact', contact)
    root.splice(contactIndex, 1)
    // const alteredIndex: BusinessContact[] = []
    // fixDirectoryIndexes(alteredIndex, state.businessDirectory, {value: 0})
    // console.log(`deleteContact => indexes to change=${alteredIndex.length}`)
    // commit('setPendingIndexUpdateContacts', alteredIndex)
  },
  deleteDepartment({commit, dispatch, state}, {deleteAll, department}) {
    console.log(`deleteDepartment => deleteAll=${deleteAll}, department=${department.id}`)
    let root = state.businessDirectory
    if (department.path?.length) {
      department.path?.forEach((pathName) => {
        root = root.find((item) => item.type === 3 && item.name === pathName)?.contacts as BusinessContact[] || root
      })
    }
    const depIndex = root.findIndex((item) => item.id === department.id)
    console.log(`deleteDepartment => depIndex=${depIndex}`)
    if (deleteAll) {
      commit('addPendingDeleteContact', department)
      root.splice(depIndex, 1)
    } else {
      console.log(`deleteDepartment => depChildren=${department.contacts?.length}`)
      commit('addPendingDeleteContact', {...department, contacts: null})
      const children = department.contacts || [];
      // const alteredPath: BusinessContact[] = []
      // fixChildrenPath(alteredPath, children, department.name)
      // console.log(`deleteDepartment => paths to change=${alteredPath.length}`)
      // commit('setPendingPathUpdateContacts', alteredPath)
      root.splice(depIndex, 1, ...children)
    }
    // const alteredIndex: BusinessContact[] = []
    // fixDirectoryIndexes(alteredIndex, state.businessDirectory, {value: 0})
    // console.log(`deleteDepartment => indexes to change=${alteredIndex.length}`)
    // commit('setPendingIndexUpdateContacts', alteredIndex)
  },
  async saveDirectoryChanges({state, commit, rootGetters}) {
    commit('setDirectoryEditMode', false);
    const businessId = rootGetters.businessId
    console.log('saveDirectoryChanges => businessId=', businessId);
    const flatDir: BusinessContact[] = []
    flattenDirectory(state.businessDirectory, flatDir)
    console.log(`onEditDepartment => flatDir=${flatDir}`)
    const directoryRef = businessDirectory(businessId);
    const batch = writeBatch(firestore);
    processItemsToDelete(state.pendingDeleteContact, directoryRef, batch)
    let alteredItems: BusinessContact[] = []
    processItemsToUpdate(state.pendingUpdateDepartments, alteredItems)
    alteredItems = removeDuplicates(alteredItems)
    fixFlatIndexes(flatDir, alteredItems)
    console.log(`onEditDepartment => alteredIndex=${alteredItems.length}`)
    fixItemsPath(null, state.businessDirectory, flatDir, alteredItems)
    console.log(`onEditDepartment => alteredIndex=${alteredItems.length}`)
    for (const item of alteredItems) {
      if (item.type !== 3) {
        batch.update(businessDirectoryContact(businessId, item.id!),
          'flatIndex', item.flatIndex,
          'path', item.path || [],
          'rules', item.rules || {})
      } else {
        batch.update(businessDirectoryContact(businessId, item.id!),
          'name', item.name,
          'expanded', item.expanded || false,
          'flatIndex', item.flatIndex,
          'path', item.path || [],
          'rules', item.rules || {});
      }
    }
    try {
      await batch.commit();
      console.log('departments are deleted');
    } catch (error) {
      console.log(error);
    }
    commit('setSelectedDepartment', null)
    commit('clearPendingDeleteContact')
    commit('clearPendingUpdateDepartments')
    commit('clearPendingIndexUpdateContacts')
    commit('clearPendingPathUpdateContacts')
  },
  discardDirectoryChanges({commit, state}) {
    commit('setDirectoryEditMode', false);
    commit('setSelectedDepartment', null)
    commit('clearPendingDeleteContact')
    commit('clearPendingUpdateDepartments')
    commit('clearPendingIndexUpdateContacts')
    commit('clearPendingPathUpdateContacts')
    commit('setBusinessDirectory', clone(state.sourceBusinessDirectory))
  },
  showToast({commit}, text) {
    commit('setSnackbar', {show: !!text, text, timeout: 5000});
  },
  clearState({commit}) {
    commit('resetState');
  }
};

function processItemsToDelete(items: BusinessContact[], directoryRef: CollectionReference, batch: WriteBatch) {
  for (const item of items) {
    console.log('processItemsToDelete => deleting itemId=', item.id);
    batch.delete(doc(directoryRef, item.id!));
    if (item.type === 3 && item.contacts?.length) {
      processItemsToDelete(item.contacts, directoryRef, batch)
    }
  }
}

function processItemsToUpdate(items: BusinessContact[], altered: BusinessContact[]) {
  for (const item of items) {
    console.log('processItemsToUpdate => updating itemId=', item.id);
    if (item.type !== 3) {
      altered.push(item)
    } else {
      altered.push({...item, contacts: null})
      if (item.contacts?.length) {
        processItemsToUpdate(item.contacts, altered)
      }
    }
  }
}

function updateChildrenPath(children: BusinessContact[], oldName: string, newName: string) {
  for (const item of children) {
    console.log('updateChildrenPath => updating itemId=', item.id);
    item.path?.splice(item.path.indexOf(oldName), 1, newName)
    if (item.type === 3 && item.contacts?.length) {
      updateChildrenPath(item.contacts, oldName, newName)
    }
  }
}

function updateChildrenVisibility(children: BusinessContact[], visibility: boolean) {
  for (const item of children) {
    console.log('updateChildrenVisibility => updating itemId=', item.id);
    item.rules!.VISIBILITY!.visible = visibility
    if (item.type === 3 && item.contacts?.length) {
      updateChildrenVisibility(item.contacts, visibility)
    }
  }
}

function fixChildrenPath(altered: BusinessContact[], children: BusinessContact[], depName: string) {
  for (const item of children) {
    console.log('fixChildrenPath => updating itemId=', item.id);
    const indexOf = item.path ? item.path?.indexOf(depName) : -1;
    if (indexOf > -1) {
      item.path?.splice(indexOf, 1)
      altered.push(item)
    }
    if (item.type === 3 && item.contacts?.length) {
      fixChildrenPath(altered, item.contacts, depName)
    }
  }
}

function fixFlatIndexes(flatDir: BusinessContact[], altered: BusinessContact[]) {
  const length = flatDir.length;
  for (let index = 0; index < length; index++) {
    const item: BusinessContact = flatDir[index]
    if (index !== item.flatIndex) {
      console.log(`fixFlatIndexes => itemId=${item.id}, flatIndex=${item.flatIndex} -> flatIndex=${index}`);
      item.flatIndex = index
      const alteredIndex = altered.findIndex((element) => element.id === item.id);
      if (alteredIndex !== -1) {
        altered.splice(alteredIndex, 1, item)
      } else {
        altered.push(item)
      }
    }
  }
}

function fixItemsPath(parentDep: BusinessContact | null, directory: BusinessContact[],
                      flatDir: BusinessContact[], altered: BusinessContact[]) {
  const targetPathStr = parentDep ? (parentDep.path?.join() || '') + parentDep.name : null
  const length = directory.length;
  for (let index = 0; index < length; index++) {
    const dirItem = directory[index]
    const item = flatDir.find((value) => value.id === dirItem.id)
    if (!item) {
      continue
    }
    if (!parentDep && !!item.path?.length) { // in root but path is not empty
      dirItem.path = []
      item.path = []
      const alteredIndex = altered.findIndex((element) => element.id === item.id);
      if (alteredIndex !== -1) {
        altered.splice(alteredIndex, 1, {...item, contacts: null})
      } else {
        altered.push({...item, contacts: null})
      }
    }
    if (!!parentDep && !!targetPathStr) {
      const itemPathStr = item.path?.join()
      if (itemPathStr !== targetPathStr) {
        dirItem.path = [...parentDep.path!, parentDep.name]
        item.path = dirItem.path
        const alteredIndex = altered.findIndex((element) => element.id === item.id);
        if (alteredIndex !== -1) {
          altered.splice(alteredIndex, 1, {...item, contacts: null})
        } else {
          altered.push({...item, contacts: null})
        }
      }
    }
    if (dirItem.type === 3 && dirItem.contacts?.length) {
      fixItemsPath(dirItem, dirItem.contacts, flatDir, altered)
    }
  }
}

function removeDuplicates(alteredItems: BusinessContact[]): BusinessContact[] {
  const predicate = (value, index, array) => array.findIndex((item) => item.id === value.id) === index;
  return alteredItems.filter(predicate)
}

export default {
  state,
  getters,
  mutations,
  actions
};
