import {ChatsState, messageType, senderType} from '@/domain/model/types';
import {ActionTree, GetterTree, MutationTree} from 'vuex';
import {firestore} from '@/plugins/firebase.init';
import axios from '@/plugins/axios';
import {pad} from '@/utils/helpers';
import rfdc from 'rfdc';
import {chat, chats, newChatMessage} from '@/data/firebase';
import {
  deleteField,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  Timestamp,
  where,
  writeBatch
} from 'firebase/firestore';

const clone = rfdc({proto: true})

const defaultState: ChatsState = {
  innerCountAll: 0,
  innerCountPersonal: 0,
  activeCountAll: 0,
  activeCountPersonal: 0,
  activeChats: [],
  busy: false,
  error: '',
  chatsNavPage: 0,
  activeCount: 0,
  inner: [],
  innerCount: 0,
  videoCall: false,
  videoSessionId: null,
  videoSession: null,
  videoMinimized: false,
  videoMuted: false,
  voiceMuted: false,
  videoTotalSeconds: 0,
  joiningVideoCall: false,
  innerUnread: [],
  activeUnread: []
};

const state: ChatsState = clone(defaultState)

const compareFn = (a, b) => {
  const atime = a.updatedDate && a.updatedDate.toMillis();
  const btime = b.updatedDate && b.updatedDate.toMillis();
  return atime === btime ? 0 : atime > btime ? -1 : 1;
};

const getters: GetterTree<ChatsState, any> = {
  activeCount: (state) => state.activeCountAll + state.activeCountPersonal,
  activeCountAll: (state) => state.activeCountAll,
  activeCountPersonal: (state) => state.activeCountPersonal,
  activeChats: (state) => state.activeChats,
  activeUrgentChats: (state) => state.activeChats.filter((item) => item.case.priority === 1),
  activeNormalChats: (state) => state.activeChats.filter((item) => item.case.priority === 2),
  activeLowChats: (state) => state.activeChats.filter((item) => item.case.priority === 3),
  inner: (state) => state.inner,
  innerCount: (state) => state.innerCountAll + state.innerCountPersonal,
  innerCountAll: (state) => state.innerCountAll,
  innerCountPersonal: (state) => state.innerCountPersonal,
  videoCall: (state) => state.videoCall,
  videoSessionId: (state) => state.videoSessionId,
  videoSession: (state) => state.videoSession,
  videoMinimized: (state) => state.videoMinimized,
  videoTotalSeconds: (state) => state.videoTotalSeconds,
  voiceMuted: (state) => state.voiceMuted,
  videoMuted: (state) => state.videoMuted,
  joiningVideoCall: (state) => state.joiningVideoCall,
  chatsNavPage: (state) => state.chatsNavPage,
  chatsError: (state) => state.error,
  chatsBusy: (state) => state.busy,
  showInnerBadge: (state, rootGetters) => !!state.inner?.find((item) => item?.memberIDs?.includes(rootGetters.t2bUser!.id!) && item?.unread && item?.unread[rootGetters.t2bUser!.id!] > 0),
  showActiveBadge: (state, rootGetters) => !!state.activeChats?.find((item) => item?.memberIDs?.includes(rootGetters.t2bUser!.id!) && item?.unread && item?.unread[rootGetters.t2bUser!.id!] > 0),
  innerUnread: (state) => state.innerUnread,
  activeUnread: (state) => state.activeUnread
};

const mutations: MutationTree<ChatsState> = {
  incrementActiveAll(state) {
    state.activeCountAll += 1
  },
  incrementActivePersonal(state) {
    state.activeCountPersonal += 1
  },
  decrementActiveAll(state) {
    state.activeCountAll -= 1
  },
  decrementActivePersonal(state) {
    state.activeCountPersonal -= 1
  },
  incrementInnerAll(state) {
    state.innerCountAll += 1
  },
  incrementInnerPersonal(state) {
    state.innerCountPersonal += 1
  },
  decrementInnerAll(state) {
    state.innerCountAll -= 1
  },
  decrementInnerPersonal(state) {
    state.innerCountPersonal -= 1
  },
  setStartBusy(state) {
    state.busy = true
    state.error = ''
  },
  setFinishBusy(state) {
    state.busy = false
  },
  setChatsError(state, value) {
    state.error = value
  },
  setChatsNavPage(state, value) {
    state.chatsNavPage = value
  },
  resetActive(state) {
    state.activeCount = 0;
    state.activeChats = [];
  },
  resetChatsState(state) {
    Object.keys(defaultState).forEach(key => state[key] = clone(defaultState)[key])
  },
  putActive(state, request) {
    state.activeChats.push(request);
  },
  updateActive(state, {chat, oldChat}) {
    state.activeChats.splice(state.activeChats.indexOf(oldChat), 1, chat);
  },
  removeActive(state, chat) {
    state.activeChats.splice(state.activeChats.indexOf(chat), 1);
  },
  resetInner(state) {
    state.innerCount = 0;
    state.inner = [];
  },
  putInner(state, chat) {
    state.inner.push(chat);
  },
  updateInner(state, {chat, oldChat}) {
    state.inner.splice(state.inner.indexOf(oldChat), 1, chat);
  },
  removeInner(state, chat) {
    state.inner.splice(state.inner.indexOf(chat), 1);
  },
  setActiveCount(state, count) {
    state.activeCount = count;
  },
  setInnerCount(state, count) {
    state.innerCount = count;
  },
  setVideoCall(state, value) {
    state.videoCall = value;
  },
  setVideoSessionId(state, id) {
    state.videoSessionId = id;
  },
  setVideoSession(state, videoSession) {
    state.videoSession = videoSession;
  },
  setVideoMinimized(state, value) {
    state.videoMinimized = value;
  },
  setVideoTotalSeconds(state, seconds) {
    state.videoTotalSeconds = seconds;
  },
  setVideoMuted(state, muted) {
    state.videoMuted = muted;
  },
  setVoiceMuted(state, muted) {
    state.voiceMuted = muted;
  },
  setJoiningVideoCall(state, value) {
    state.joiningVideoCall = value;
  },
  addInnerUnread(state, value) {
    state.innerUnread.push(value)
  },
  updateInnerUnread(state, {id, unread}) {
    state.innerUnread.splice(state.innerUnread.findIndex((item) => item.id === id), 1, {id, unread})
  },
  removeInnerUnread(state, id) {
    state.innerUnread.splice(state.innerUnread.findIndex((item) => item.id === id), 1)
  },
  addActiveUnread(state, value) {
    state.activeUnread.push(value)
  },
  updateActiveUnread(state, {id, unread}) {
    state.activeUnread.splice(state.activeUnread.findIndex((item) => item.id === id), 1, {id, unread})
  },
  removeActiveUnread(state, id) {
    state.activeUnread.splice(state.activeUnread.findIndex((item) => item.id === id), 1)
  }
};

const actions: ActionTree<ChatsState, any> = {
  //deprecated
  bindVideoSession({state, commit, rootGetters}) {
    const videoSessionId = state.videoSessionId;
    const userId = rootGetters.t2bUser && rootGetters.t2bUser.id;
    if (!videoSessionId || !userId) {
      return;
    }
    return onSnapshot(chat(videoSessionId),
      (snapshot) => {
        commit('setVideoSession', {id: snapshot.id, ...snapshot.data()});
      });
  },

  //deprecated
  async startVideoCall_({commit, rootGetters}) {
    const selectedChat = rootGetters.selectedTextSession;
    const chatId = rootGetters.selectedChatId;
    const t2bUser = rootGetters.t2bUser;
    const userId = t2bUser && t2bUser.id;
    const userName = t2bUser && t2bUser.fullName;
    if (!chatId || !selectedChat || !userId) {
      return;
    }
    try {
      await writeBatch(firestore)
        .set(chat(chatId), {
          videoSession: {
            sessionId: '',
            members: {
              [userId]: true
            },
            caller: userId
          }
        }, {merge: true})
        .set(newChatMessage(chatId), {
          text: 'Video call started',
          sender: {name: userName, uid: userId, id: userId, type: senderType.SYSTEM},
          textSessionId: chatId,
          type: messageType.VIDEO_CALL_START,
          createdDate: Timestamp.now(),
          memberIDs: selectedChat.memberIDs
        }, {merge: true})
        .commit();

      commit('setVideoSessionId', chatId);
      console.log(`video session id=${chatId} has been started`);
    } catch (error) {
      console.error(error);
    }
  },
  //deprecated
  async endVideoCall_({state, commit, rootGetters}) {
    const videoSession = state.videoSession;
    const videoSessionId = state.videoSessionId;
    const t2bUser = rootGetters.t2bUser;
    const userId = t2bUser && t2bUser.id;
    const userName = t2bUser && t2bUser.fullName;
    if (!videoSessionId || !userId) {
      return;
    }
    try {
      const seconds = pad(state.videoTotalSeconds % 60);
      const minutes = pad(parseInt(String(state.videoTotalSeconds / 60)));
      await writeBatch(firestore)
        .update(chat(videoSessionId), 'videoSession', deleteField())
        .set(newChatMessage(videoSessionId), {
          text: `Video call ended. Duration: ${minutes}:${seconds}`,
          sender: {name: userName, uid: userId, id: userId, type: senderType.SYSTEM},
          textSessionId: videoSessionId,
          type: messageType.VIDEO_CALL_END,
          createdDate: Timestamp.now(),
          memberIDs: videoSession.memberIDs
        }, {merge: true})
        .commit();
      commit('setVideoSessionId', null);
      commit('setVideoSession', null);
      commit('setVideoMuted', false);
      commit('setVoiceMuted', false);
      commit('setVideoTotalSeconds', 0);
      console.log(`video session id=${videoSessionId} has been ended`);
    } catch (error) {
      console.error(error);
    }
  },
  //deprecated
  async joinVideoCall_({state, rootGetters}) {
    const selectedChat = rootGetters.selectedTextSession;
    const chatId = rootGetters.selectedChatId;
    const t2bUser = rootGetters.t2bUser;
    const userId = t2bUser && t2bUser.id;
    const userName = t2bUser && t2bUser.fullName;
    if (!chatId || !selectedChat || !userId) {
      return;
    }
    try {
      await setDoc(chat(chatId), {videoSession: {members: {[userId]: true}}}, {merge: true});
      console.log(`video session id=${chatId} has been joined`);
    } catch (error) {
      console.error(error);
    }
  },
  onJoinVideoCall_({commit}) {
    commit('setJoiningVideoCall', true)
    commit('setVideoCall', true)
  },
  minimizeVideo({commit}, totalSeconds) {
    commit('setVideoTotalSeconds', totalSeconds);
    commit('setVideoMinimized', true);
    commit('setVideoCall', false);
  },
  normalizeVideo({commit}, totalSeconds) {
    commit('setVideoTotalSeconds', totalSeconds);
    commit('setVideoCall', true);
    commit('setVideoMinimized', false);
  },
  async createActiveChat({commit, rootGetters, dispatch}, {customerId, contactId}) {
    const associateId = rootGetters.t2bUser && rootGetters.t2bUser.id;
    const businessId = rootGetters.business && rootGetters.business.id;
    if (!associateId || !businessId) {
      return null;
    }
    commit('setStartBusy')
    try {
      const currentUser = rootGetters.currentUser;
      const token = currentUser ? await currentUser.getIdToken(false) : '';
      const result = await axios.post('/chatsService.createActive', {
        businessId,
        customerId,
        associateId: contactId,
        creator: 1 // associate
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (result.data.status === 'OK') {
        // dispatch('selectConversation', result.data.textSession);
        commit('setFinishBusy')
        return result.data.textSession;
      } else {
        commit('setChatsError', result.data.message)
      }
    } catch (err: any) {
      commit('setChatsError', err.message)
    }
    commit('setFinishBusy')
    return null;
  },
  async createInnerChat({commit, rootGetters, dispatch}, {contactIds, title}) {
    const userId = rootGetters.t2bUser && rootGetters.t2bUser.id;
    const businessId = rootGetters.business && rootGetters.business.id;
    if (!userId || !businessId) {
      return null;
    }
    commit('setStartBusy')
    try {
      const currentUser = rootGetters.currentUser;
      const token = currentUser ? await currentUser.getIdToken(false) : '';
      const data: any = {
        businessId,
        fromId: userId
      };
      if (!!title) {
        data.title = title
      }
      if (contactIds.length > 1) {
        data.toIds = contactIds
      } else {
        data.toId = contactIds[0]
      }
      const result = await axios.post('/textsessions/inner', data, {
        headers: {Authorization: `Bearer ${token}`,},
        validateStatus: (status) => status === 200 || status === 412
      })
      if (result.data.status === 'OK') {
        // dispatch('selectConversation', result.data.textSession);
        commit('setFinishBusy')
        return result.data.textSessionId;
      } else if (result.status === 412) {
        commit('setChatsError', result.data.message)
      }
    } catch (err: any) {
      commit('setChatsError', err.message)
    }
    commit('setFinishBusy')
    return null;
  },
  async addChatMembers({commit, rootGetters, dispatch}, {sender, contactIds, title}) {
    const chatId = rootGetters.selectedChatId;
    const userId = rootGetters.t2bUser && rootGetters.t2bUser.id;
    const businessId = rootGetters.business && rootGetters.business.id;
    if (!chatId || !userId || !businessId || !sender) {
      commit('setChatsError', 'bad request params')
      return null;
    }
    commit('setStartBusy')
    try {
      const currentUser = rootGetters.currentUser;
      const token = currentUser ? await currentUser.getIdToken(false) : '';
      const result = await axios.post(
        `/textsessions/${chatId}/members`,
        {
          sender, contactIds, title, businessId
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      if (result.data.status === 'Created') {
        commit('setFinishBusy')
        return true;
      }
    } catch (err: any) {
      commit('setChatsError', err.message)
    }
    commit('setFinishBusy')
    return false;
  },
  loadActive({commit, getters, rootGetters}) {
    const businessId = rootGetters.t2bUser && rootGetters.t2bUser.business.id;
    const userId = rootGetters.t2bUser && rootGetters.t2bUser.id;
    if (!businessId || !userId) {
      return null;
    }
    const isAdmin = rootGetters.isAdmin
    let chatsQuery = query(chats,
      where('business.id', '==', businessId),
      where('case.status', '==', 2))
    if (!isAdmin) {
      chatsQuery = query(chatsQuery, where('memberIDs', 'array-contains', userId))
    }
    chatsQuery = query(chatsQuery, orderBy('updatedDate', 'desc'))
    if (getters.activeChats.length) {
      chatsQuery = query(chatsQuery, startAfter(getters.activeChats[0].updatedDate))
    }
    return onSnapshot(chatsQuery,
      (snapshot) => {
        snapshot.docChanges().forEach((docChange) => {
          const chatData = docChange.doc.data();
          chatData.id = docChange.doc.id;
          switch (docChange.type) {
            case 'added':
              if (state.activeChats.findIndex((item) => item.id === chatData.id) === -1) {
                commit('putActive', chatData);
                if (chatData.memberIDs.includes(userId)) {
                  if (!!chatData.unread) {
                   commit('addActiveUnread', {id : chatData.id, unread: chatData.unread[userId]})
                  }
                  commit('incrementActivePersonal')
                } else {
                  commit('incrementActiveAll')
                }
              }
              break;
            case 'modified':
              const modified = state.activeChats.find((item) => !!item && item.id === chatData.id);
              if (chatData.memberIDs.includes(userId) && !!chatData.unread) {
                commit('updateActiveUnread', {id : chatData.id, unread: chatData.unread[userId]})
              }
              commit('updateActive', {chat: chatData, oldChat: modified});
              break;
            case 'removed':
              const removed = state.activeChats.find((item) => !!item && item.id === chatData.id);
              commit('removeActive', removed);
              if (chatData.memberIDs.includes(userId)) {
                commit('removeActiveUnread', chatData.id)
                commit('decrementActivePersonal')
              } else {
                commit('decrementActiveAll')
              }
              break;
            default:
          }
        });
        getters.activeChats.sort(compareFn)
      });
  },
  loadInner({commit, getters, rootGetters, dispatch}) {
    const businessId = rootGetters.t2bUser && rootGetters.t2bUser.business.id;
    const userId = rootGetters.t2bUser && rootGetters.t2bUser.id;
    if (!businessId || !userId) {
      return null;
    }
    const isAdmin = rootGetters.isAdmin
    let chatsQuery = query(chats,
      where('business.id', '==', businessId),
      where('type', '==', 3))
    if (!isAdmin) {
      chatsQuery = query(chatsQuery, where('memberIDs', 'array-contains', userId))
    }
    chatsQuery = query(chatsQuery, orderBy('updatedDate', 'desc'))
    if (getters.inner.length) {
      chatsQuery = query(chatsQuery, startAfter(getters.inner[0].updatedDate))
    }
    return onSnapshot(chatsQuery, (snapshot) => {
      snapshot.docChanges().forEach((docChange) => {
        const chatData = docChange.doc.data();
        chatData.id = docChange.doc.id;
        switch (docChange.type) {
          case 'added':
            commit('putInner', chatData);
            if (chatData.memberIDs.includes(userId)) {
              if (!!chatData.unread) {
                commit('addInnerUnread', {id : chatData.id, unread: chatData.unread[userId]})
              }
              commit('incrementInnerPersonal')
            } else {
              commit('incrementInnerAll')
            }
            break;
          case 'modified': {
            const oldChat = state.inner.find((item) => item.id === chatData.id);
            if (oldChat) {
              commit('updateInner', {chat: chatData, oldChat});
              if (chatData.memberIDs.includes(userId) && !!chatData.unread) {
                commit('updateInnerUnread', {id : chatData.id, unread: chatData.unread[userId]})
              }
              if (chatData.memberIDs.includes(userId) && !oldChat.memberIDs.includes(userId)) {
                commit('incrementInnerPersonal')
              } else if (!chatData.memberIDs.includes(userId) && oldChat.memberIDs.includes(userId)) {
                commit('decrementInnerPersonal')
              }
            }
            break;
          }
          case 'removed': {
            const oldChat = state.inner.find((item) => item.id === chatData.id);
            commit('removeInner', oldChat);
            if (chatData.memberIDs.includes(userId)) {
              commit('removeInnerUnread', chatData.id)
              commit('decrementInnerPersonal')
            } else {
              commit('decrementInnerAll')
            }
            break;
          }
          default:
        }
      });
      getters.inner.sort(compareFn)
    });
  },
  changeChatPriority({commit}, {id, priority}) {
    if (!id) {
      return;
    }
    return setDoc(chat(id), {
      case: {priority},
      updatedDate: Timestamp.now()
    }, {merge: true});
  },
  async joinVideoCall2({commit, rootGetters, dispatch}, {chatId, videoCallId, videoSessionId}) {
    console.log('joinVideoCall2')
    try {
      const currentUser = rootGetters.currentUser;
      const token = currentUser ? await currentUser.getIdToken(false) : '';
      const result = await axios.patch(`/textsessions/${chatId}/videocalls/${videoCallId}`, {
        sessionId: videoSessionId || ''
      }, {
        headers: {Authorization: `Bearer ${token}`}
      });
      console.log(result)
      if (result.status === 200) {
        return result.data;
      }
    } catch (err) {
      console.error(err)
    }
    return null;
  },
  clearState({commit}) {
    commit('resetChatsState');
  }
};

export default {
  state, getters, mutations, actions
}
